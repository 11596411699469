import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, Button, Stepper, Step, StepLabel, Grid, Paper } from "@mui/material";
import QRCode from "react-qr-code";
import DownloadIcon from "@mui/icons-material/Download";
import { useSearchParams } from "react-router-dom";
import * as uuid from "uuid";
import moment from "moment";

const mainColor = "#535de8"; // Main color for the application




const Visitor = () => {
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Visitor registration', 'Visit Reason', 'Pre-registration'];

  const [searchParams] = useSearchParams();

  const [visitor, setVisitor] = useState({} as any)

  const stepContents = [
    {
      status: "Pending",
      time: visitor?.createdAt ? moment(visitor?.createdAt).format('YYYY-MM-DD HH:mm:ss') : '',
      labelCn: "預約完成",
      labelEn: "Pre-registration Success",
      infoCn: "請保留此 QR Code 進入 及 離開 均必須使用",
      infoEn: "Please keep this QR Code For enter and exit propose"
    },
    {
      status: "Entered",
      time: visitor?.enterTime ? moment(visitor?.enterTime).format('YYYY-MM-DD HH:mm:ss') : '',
      labelCn: "已進入",
      labelEn: "Check-in",
      infoCn: "請保留此 QR Code 進入 及 離開 均必須使用",
      infoEn: "Please keep this QR Code For enter and exit propose"
    },
    {
      status: "Leaved",
      time: visitor?.leaveTime ? moment(visitor?.leaveTime).format('YYYY-MM-DD HH:mm:ss') : '',
      labelCn: "已離開",
      labelEn: "Check-out",
      infoCn: "你已離開，此QR Code 已無效，謝謝。",
      infoEn: "You have leaved. This QR Code is invalid. Thank you."
    }
  ];
  useEffect(() => {
    const secret = searchParams.get("secret")
    if (secret) {
      try {
        fetch(`${process.env.REACT_APP_API_PATH}/visitors/profile`, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            secret,
          })
        }).then(res => {
          if (res.status === 200) {
            res.json().then(data => {
              console.log(data)
              setVisitor(data)
              setActiveStep(stepContents?.findIndex((item) => item.status === data.status) ?? 0)
            })

          } else {
            throw new Error('失敗')
          }
        })

      } catch (e) {
        alert('失敗')
      }
    }

  }, [searchParams.get("secret")])


  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4, '.MuiStepIcon-root.Mui-active': { color: mainColor }, '.MuiStepIcon-root.Mui-completed': { color: mainColor } }}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel sx={{ '.MuiStepLabel-label': { color: mainColor, fontWeight: 'bold' } }}>{stepContents[index].labelCn}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Paper elevation={2} sx={{ mb: 4, p: 2 }}>
        <VisitorCode content={stepContents[activeStep]} secret={searchParams.get("secret")} />
      </Paper>
    </Box>
  );
};

const VisitorCode = ({ secret, content }) => {
  const [qrCode, setQrCode] = useState('');
  const qrRef = useRef(null);


  useEffect(() => {
    if (qrRef.current) {
      const svg = qrRef.current.querySelector('svg');
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement("canvas");
      canvas.width = 256;
      canvas.height = 256;
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = () => {
        ctx.drawImage(img, 0, 0);
        setQrCode(canvas.toDataURL("image/png"));
      };
      img.src = "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svgData)));
    }
  }, [qrRef]);

  return (
    <Box sx={{ textAlign: 'center', p: 2 }}>
      <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: mainColor }}>
        {content.time}
      </Typography>
      <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: mainColor }}>
        {content.labelCn}
      </Typography>
      <Typography variant="body2" gutterBottom sx={{ fontWeight: 'bold', color: mainColor }}>
        {content.labelEn}
      </Typography>
      <Box ref={qrRef} sx={{ my: 2 }}>
        <QRCode
          id={uuid.v4()}
          size={256}
          value={secret ?? "Your QR Code Value Here"}
        />
      </Box>
      <Typography variant="caption" gutterBottom sx={{ fontWeight: 'bold' }}>
        {content.infoCn}
      </Typography>
      <Typography variant="body2" gutterBottom sx={{ fontWeight: 'bold' }}>
        {content.infoEn}
      </Typography>
      <Button
        variant="outlined"
        startIcon={<DownloadIcon />}
        onClick={() => {
          if (qrCode) {
            const a = document.createElement("a");
            a.download = `${secret ?? "visitor"}.png`;
            a.href = qrCode;
            a.click();
          }
        }}
        sx={{
          mt: 2,
          borderColor: mainColor,
          color: mainColor,
          fontWeight: 'bold',
          '&:hover': {
            borderColor: mainColor,
            backgroundColor: `${mainColor}22`
          }
        }}
      >
        下載截圖 Download Image
      </Button>
    </Box>
  );
};
export default Visitor;
