import React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stepper from "@mui/material/Stepper";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import { Icon } from "@mui/material";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import Avatar from "@mui/material/Avatar";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";

const Visitor = () => {
  const [inputValue, setInputValue] = React.useState(""); // State to handle the input value
  const [flats, setFlats] = React.useState([]); // State to handle the array of flats
  const [visitorName, setVisitorName] = React.useState<string | null>(null);
  const [idNumber, setIdNumber] = React.useState<string | null>(null);
  const [visitPurpose, setVisitPurpose] = React.useState<string | null>(null);
  const [phone, setPhone] = React.useState<string | null>(null);
  const [building, setBuilding] = React.useState<number | null>(null);

  // Function to handle when the input value changes
  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    // If the user stops typing for a specific duration, add the flat
    const delayDebounceFn = setTimeout(() => {
      if (newInputValue.trim() !== "") {
        setFlats([...flats, newInputValue.trim()]);
        setInputValue(""); // Clear the input box after adding the flat
      }
    }, 1000); // 1 second delay

    return () => clearTimeout(delayDebounceFn); // Clear timeout if the user starts typing again within the delay
  };
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  // State to manage the selected visit reason

  // Handler to change the selected visit reason
  const handleReasonChange = (value) => {
    setVisitPurpose(value);
  };
  const centeredContentStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%", // Use 100% width for responsiveness
    padding: theme.spacing(2), // Use theme spacing for consistency
  };
  const fixedBottomStyle = {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
  };
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const handleAddTag = () => {
    if (inputValue.trim() !== "") {
      setFlats([...flats, inputValue.trim()]);
      setInputValue(""); // Clear the input box after adding the flat
    }
  };
  const buildings = [
    {
      label: "樓盤 1 Building 1",
      value: 1,
    },
  ];

  function getStyles(name, buildings, theme) {
    return {
      fontWeight:
        buildings.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  const handleDeleteTag = (flatToDelete) => () => {
    setFlats((flats) => flats.filter((flat) => flat !== flatToDelete));
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (!isNaN(Number(value))) {
      setBuilding(Number(value));
    }
  };

  const steps = [
    {
      label: "來訪登記 Visitor registration",
      component: (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 2,
          }}
        >
          <Stack spacing={3} sx={{ width: "100%" }}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel id="demo-multiple-name-label">
                樓盤 Building
              </InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={
                  buildings?.find((b) => b.value === building)?.value || ""
                }
                onChange={handleChange}
                input={<OutlinedInput label="Name" />}
                MenuProps={MenuProps}
              >
                {buildings.map((building, i) => (
                  <MenuItem
                    key={i}
                    value={building.value}
                    style={getStyles(building, buildings, theme)}
                  >
                    {building.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Autocomplete
              multiple
              id="flats-standard"
              options={[]} // Assume you have no specific options to show
              freeSolo
              value={flats}
              onChange={(event, newValue) => setFlats(newValue)}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) =>
                setInputValue(newInputValue)
              }
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={index}
                    label={option}
                    {...getTagProps({ index })}
                    onDelete={() => handleDeleteTag(option)}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="到訪單位 Residence"
                  placeholder="Add flats"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                        <Button onClick={handleAddTag}>Add</Button>
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />

            <TextField
              required
              id="outlined-required1"
              label="名字 Name"
              value={visitorName}
              placeholder="名字 Name"
              onChange={(event) => {
                const {
                  target: { value },
                } = event;
                setVisitorName(value);
              }}
            />
            <TextField
              required
              id="outlined-required2"
              label="身份證明文件號碼頭4位  / ID Number (First 4 digit)"
              placeholder="身份證明文件號碼頭4位  / ID Number (First 4 digit)"
              value={idNumber}
              onChange={(event) => {
                const {
                  target: { value },
                } = event;
                setIdNumber(value);
              }}
            />
            <TextField
              required
              id="outlined-required3"
              label="電話號碼 Phone Number"
              placeholder="電話號碼 Phone Number"
              value={phone}
              onChange={(event) => {
                const {
                  target: { value },
                } = event;
                setPhone(value);
              }}
            />
          </Stack>
        </Box>
      ),
    },
    {
      label: "來訪原因 Visit Reason",
      component: (
        <FormControl component="fieldset" fullWidth>
          <RadioGroup
            aria-label="visitPurpose"
            name="visitPurpose"
            value={visitPurpose}
          >
            <Stack spacing={2}>
              {[
                { label: "探訪 Visit", value: "Visit", icon: "place" },
                {
                  label: "送貨 Goods Delivery",
                  value: "Goods Delievery",
                  icon: "local_shipping",
                },
                {
                  label: "外賣 Food Delivery",
                  value: "Food Delivery",
                  icon: "restaurant",
                },
                {
                  label: "維修 Maintenance",
                  value: "Maintenance",
                  icon: "build",
                },
              ].map((option, i) => (
                <Card key={option.value} variant="outlined">
                  <CardActionArea
                    onClick={() => handleReasonChange(option.value)}
                  >
                    <CardContent sx={{ display: "flex", alignItems: "center" }}>
                      <Icon>{option.icon}</Icon>
                      <Typography
                        variant="subtitle1"
                        sx={{ flexGrow: 1, marginLeft: 2 }}
                      >
                        {option.label}
                      </Typography>
                      <Radio
                        checked={visitPurpose === option.value}
                        value={option.value}
                      />
                    </CardContent>
                  </CardActionArea>
                </Card>
              ))}
            </Stack>
          </RadioGroup>
        </FormControl>
      ),
    },
  ];
  const maxSteps = steps.length;

  const handleNext = async () => {
    if (activeStep < maxSteps - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      try {
        const rawResponse = await fetch(
          `${process.env.REACT_APP_API_PATH}/visitors/register`,
          {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              visitorName,
              idNumber,
              visitPurpose,
              phone,
              flats: flats.join(","),
              building,
            }),
          }
        );
        const content = await rawResponse.json();
        console.log(content);
        if (content.secret) {
          window.location.href = "/visitor/code?secret=" + content.secret;
        }
      } catch (e) {
        alert("登記失敗");
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ pb: 7, position: "relative", minHeight: "100vh" }}>
      {" "}
      {/* Padding bottom for safe area and relative positioning */}
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ padding: theme.spacing(2) }} // Padding for the content
      >
        <Grid
          item
          xs={12}
          style={{
            maxWidth: "600px",
            width: "100%",
            margin: "0 auto",
            marginBottom: theme.spacing(4),
          }}
        >
          {" "}
          {/* Added marginBottom here */}
          <Typography variant="h4" align="center" style={{ margin: "20px 0" }}>
            快捷訪客登記 <br />
            Quick visitor registration
          </Typography>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            sx={{ width: "100%" }}
          >
            {steps.map((step, index) => (
              <Step key={step.label + index}>
                <StepLabel>
                  <div>{step.label.split(" ")[0]}</div> {/* Chinese text */}
                  <div>{step.label.split(" ").slice(1).join(" ")}</div>{" "}
                  {/* English text */}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          {steps[activeStep].component}
        </Grid>
      </Grid>
      {/* Fixed Bottom Navigation Buttons */}
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1200,
          display: "flex",
          justifyContent: "center", // Center the buttons horizontally
          p: 1, // Add padding around the buttons
        }}
      >
        <Button
          variant="contained"
          color="secondary" // Color changed to secondary
          onClick={handleBack}
          disabled={activeStep === 0}
          sx={{
            width: "calc(50% - 8px)", // Subtract 8px for the gap between buttons
            borderTopLeftRadius: "30px", // Increased border radius for a smoother curve
            borderBottomLeftRadius: "30px", // Same for bottom-left corner
            height: "56px",
            boxShadow: "0px 3px 15px rgba(0,0,0,0.2)",
            "&:hover": {
              boxShadow: "0px 5px 20px rgba(0,0,0,0.3)",
              transform: "translateY(-2px)",
            },
            transition: "all 0.3s ease",
            mr: "8px", // Margin right to separate the buttons
          }}
        >
          上一步 Back
        </Button>
        <Button
          variant="contained"
          color="primary" // Color changed to primary
          onClick={handleNext}
          disabled={activeStep === maxSteps}
          sx={{
            width: "calc(50% - 8px)", // Subtract 8px for the gap between buttons
            borderTopRightRadius: "30px", // Increased border radius for a smoother curve
            borderBottomRightRadius: "30px", // Same for bottom-right corner
            height: "56px",
            boxShadow: "0px 3px 15px rgba(0,0,0,0.2)",
            "&:hover": {
              boxShadow: "0px 5px 20px rgba(0,0,0,0.3)",
              transform: "translateY(-2px)",
            },
            transition: "all 0.3s ease",
            ml: "8px", // Margin left to separate the buttons
          }}
        >
          {activeStep === maxSteps - 1 ? "完成 Finish" : "下一步 Next"}
        </Button>
      </Box>
    </Box>
  );
};

export default Visitor;
